const apiUrl = {
    signIn: '/auth/sign-in',
    signUp: '/users/sign-up',
    sendCode: '/users/send-code',
    verifyCode: '/users/verify-code',
    createContact: '/contacts/create-contact',
    users: '/users',
    password: '/password',
    createSession: '/payments/create-session',
    resetPassword: '/users/reset-password',
    resetPasswordVerify: '/users/reset-password/verify',
    profiles: '/profiles',
    spendExportCredit: '/profiles/export/check-credits',
    getInvoices: '/payments/user-invoices',
    getBillingInfo: '/users/billing-information',
    getLastSubscription: '/payments/last-subscription',
    cancelSubcription: '/payments/cancel-subscription',
    upgradeSubscription: '/payments/upgrade-subscription',
    contactUs: '/contacts',
    countries: '/find-profile/countries',
    regions: '/find-profile/regions',
    profileCount: '/find-profile/count',
    createUserLog: '/user-logs',
    getAllUsers: '/admin/users',
    getUserDetails: '/admin/user-details',
    searchHistory: '/search-history',
    deleteSearchHistoryById: '/search-history/delete-by-id',
    lists: '/saved-lists',
    savedSearch: '/saved-search',
    deleteByTimeRange: '/search-history/delete-by-time-range',
    addProfilesToLists: '/saved-lists/add-profiles',
    deleteProfilesFromLists: '/saved-lists/delete-profiles',
    profileContact: '/profile-contact',
    favorites: '/saved-lists/favorites',
    getWorkEmail: '/profile-contact/work-email',
    userContacts: '/users/contacts',
    contactsByTimeRange: '/users/contacts-by-time-range',
    exportsByTimeRange: '/users/exports-by-time-range',
    notes: '/notes',
    notesCount: '/notes/count',
    credits: '/users/credits',
    creditPackages: '/users/credit-packages',
    getContactsByLinkedInUrl: '/profile-contact/linkedin-url',
    getWorkEmailByLinkedInUrl: '/profile-contact/work-email-by-linkedin-url'
};

export default apiUrl;
