// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-area_container__QN0LB {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: stretch;\n  overflow: hidden;\n  border-radius: var(--input-border-radius);\n  color: var(--text-color-primary);\n  background: #FFFFFF;\n  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2); }\n  .text-area_container__QN0LB textarea {\n    color: var(--text-color-primary); }\n", "",{"version":3,"sources":["webpack://./src/components/text-area/text-area.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,wBAAwB;EACxB,gBAAgB;EAEhB,yCAAyC;EACzC,gCAAgC;EAChC,mBAAmB;EACnB,0CAA0C,EAAA;EAV9C;IAaQ,gCAAgC,EAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: stretch;\n    overflow: hidden;\n\n    border-radius: var(--input-border-radius);\n    color: var(--text-color-primary);\n    background: #FFFFFF;\n    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);\n\n    textarea {\n        color: var(--text-color-primary);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "text-area_container__QN0LB"
};
export default ___CSS_LOADER_EXPORT___;
