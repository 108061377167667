import React from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
    const location = useLocation();

    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Custom Title' });

    return <div></div>;
};

export default RouteChangeTracker;
