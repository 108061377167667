const checkExpired = timePeriod => {
    if (timePeriod) {
        // This line sets the hour of the current date to midnight
        const today = new Date().setHours(0, 0, 0, 0);
        const thatDay = new Date(timePeriod * 1000).setHours(0, 0, 0, 0);
        return today > thatDay;
    }
    return true;
};

export default checkExpired;
