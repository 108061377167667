import React from 'react';

export default function SearchHistory({ width = '12', height = '12', fill = 'white', onClick }) {
    return (
        <svg width={width} height={height} onClick={onClick} viewBox="0 0 99 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M52.3229 22.4854H46.6862C46.1706 22.4854 45.7487 22.9072 45.7487 23.4229V55.6846C45.7487 55.9893 45.8893 56.2705 46.1354 56.4463L65.5065 70.5908C65.9284 70.8955 66.5143 70.8135 66.819 70.3916L70.1706 65.8213C70.487 65.3877 70.3932 64.8018 69.9714 64.5088L53.2604 52.4268V23.4229C53.2604 22.9072 52.8385 22.4854 52.3229 22.4854ZM78.0807 31.2979L96.4557 35.7861C97.0417 35.9268 97.6159 35.4815 97.6159 34.8838L97.7096 15.958C97.7096 15.1729 96.8073 14.7276 96.1979 15.2197L77.7292 29.6455C77.5896 29.7535 77.4833 29.8987 77.4226 30.0644C77.3618 30.2301 77.3491 30.4095 77.3858 30.5821C77.4225 30.7548 77.5072 30.9135 77.6302 31.0401C77.7531 31.1667 77.9093 31.2561 78.0807 31.2979V31.2979ZM97.7331 66.583L91.0885 64.2979C90.8569 64.2185 90.6033 64.2328 90.3821 64.338C90.161 64.4431 89.9897 64.6306 89.9049 64.8604C89.6823 65.458 89.4479 66.044 89.2018 66.6299C87.1159 71.5635 84.1276 76.0049 80.3073 79.8135C76.5294 83.6031 72.0521 86.6238 67.1237 88.708C62.0182 90.8666 56.5301 91.9746 50.987 91.9658C45.3854 91.9658 39.9596 90.876 34.8503 88.708C29.9218 86.6238 25.4446 83.6031 21.6667 79.8135C17.8581 76.0049 14.8698 71.5635 12.7721 66.6299C10.6255 61.5215 9.52562 56.0343 9.53776 50.4932C9.53776 44.8916 10.6276 39.4541 12.7956 34.3447C14.8815 29.4111 17.8698 24.9697 21.6901 21.1611C25.468 17.3715 29.9453 14.3509 34.8737 12.2666C39.9596 10.0986 45.3971 9.0088 50.9987 9.0088C56.6003 9.0088 62.026 10.0986 67.1354 12.2666C72.0639 14.3509 76.5411 17.3715 80.319 21.1611C81.5143 22.3682 82.6393 23.6221 83.6706 24.9463L90.6784 19.4619C81.4557 7.67287 67.1003 0.0908339 50.9753 0.102553C22.8971 0.114271 0.350259 22.919 0.631509 51.0088C0.912759 78.6065 23.3542 100.884 50.9987 100.884C72.737 100.884 91.2526 87.1026 98.3073 67.8018C98.4831 67.3096 98.2253 66.7588 97.7331 66.583V66.583Z" fill={fill} />
        </svg>

    );
}
