/* eslint-disable no-nested-ternary */
import React, { Suspense, lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// contexts
import { useBackend } from 'contexts/backend';

// helpers
import { createUserLog } from 'helpers/requests/user-log';

// components
import Page from 'components/page';
import Footer from 'components/footer';
import Header from 'components/header';

// styles
import styles from './app.module.scss';
import SavedSearches from 'pages/saved-searches';

// lazy-load pages
const SignIn = lazy(() => import('pages/sign-in'));
const SignUp = lazy(() => import('pages/sign-up'));
const Home = lazy(() => import('pages/home'));
const SearchProfile = lazy(() => import('pages/search-profile'));
const ViewProfile = lazy(() => import('pages/view-profile'));
const ViewList = lazy(() => import('pages/view-list'));
const VerifyMail = lazy(() => import('pages/verify-mail'));
const ForgotPassword = lazy(() => import('pages/forgot-password'));
const Settings = lazy(() => import('pages/settings'));
const ChangePassword = lazy(() => import('pages/change-password'));
const BillingAddress = lazy(() => import('pages/billing-address'));
const PaymentSuccess = lazy(() => import('pages/payment-success'));
const PaymentFail = lazy(() => import('pages/payment-fail'));
const CancelSubscription = lazy(() => import('pages/settings/billing/cancel-subscription'));
const CancelSubscriptionSuccess = lazy(() => import('pages/settings/billing/cancel-subscription-success'));
const Legal = lazy(() => import('pages/legal'));
const NotFound = lazy(() => import('pages/not-found'));
const Landing = lazy(() => import('pages/landing'));
const ContactUs = lazy(() => import('pages/contact-us'));
const Skills = lazy(() => import('pages/skills'));
const Users = lazy(() => import('pages/users'));
const Lists = lazy(() => import('pages/lists'));
const ListProfiles = lazy(() => import('pages/list-profiles'));
const Notes = lazy(() => import('pages/notes'));
const SearchHistory = lazy(() => import('pages/search-history'));
const CreditHistory = lazy(() => import('pages/credit-history'));
const UserDetails = lazy(() => import('pages/user-details'));
const UpgradePlan = lazy(() => import('pages/upgrade-plan'));
const AddMoreCredits = lazy(() => import('pages/add-more-credits'));
const GetContactByUrl = lazy(() => import('pages/contact-by-url'));

const App = () => {
    const navigate = useNavigate();

    const backend = useBackend();
    const user = backend.getItem('user');
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        const url = [location.pathname, location.search, location.hash].join('');
        createUserLog(backend, { action: 'window.navigate', data: url });
    }, [backend, location.pathname, location.search, location.hash]);

    useEffect(() => {
        if (user && !user.verified && pathname !== '/verify-mail') {
            navigate('/verify-mail');
        }
    }, [user, navigate, pathname]);

    return (
        <Suspense>
            <ToastContainer />
            <Page className={styles.app}>
                {pathname === '/' ? <Header style='light' /> : <Header style='dark' />}
                <Page.Body className={styles.body} style={(pathname === '/' || pathname === '/contact-us') ? { backgroundColor: 'white' } : null}>
                    <Routes>
                        {/* PUBLIC ROUTES */}
                        <Route path='/' element={<Landing />} />
                        <Route path='/contact-us' element={<ContactUs />} />
                        <Route path='/legal/:key' element={<Legal />} />
                        <Route path='skills' element={<Skills />} />
                        <Route path='/search-profile' element={<SearchProfile />} />
                        <Route path='/upgrade-plan' element={<UpgradePlan />} />
                        <Route path='/add-credits' element={<AddMoreCredits />} />
                        <Route path='/view-profile/:id' element={<ViewProfile />} />
                        <Route path='/*' element={<NotFound />} />

                        {/* NOT AUTHENTICATION ROUTES */}
                        {user != null ? <Route path='*' element={<Navigate to='/billing-address' replace />} /> : <>
                            <Route path='/sign-in' element={<SignIn />} />
                            <Route path='/sign-up' element={<SignUp />} />
                            <Route path='/forgot-password' element={<ForgotPassword />} />
                            <Route path='/change-password' element={<ChangePassword />} />
                            <Route path='/*' element={<NotFound />} />
                        </>}

                        {/* AUTHENTICATION ROUTES */}
                        {user == null ? <Route path='*' element={<Navigate to='/sign-in' replace />} /> : <>
                            <Route path='/home' element={<Home />} />
                            <Route path='/billing-address' element={<BillingAddress />} />
                            <Route path='/verify-mail' element={<VerifyMail />} />
                            <Route path='/settings/:key' element={<Settings />} />
                            <Route path='/payment-success' element={<PaymentSuccess />} />
                            <Route path='/payment-fail' element={<PaymentFail />} />
                            <Route path='/u-f33c47fe-c69e-41bc-b4fe-5ecf591089a4' element={<Users />} />
                            <Route path='/lists' element={<Lists />} />
                            <Route path='/saved-searches' element={<SavedSearches />} />
                            <Route path='/lists/:id' element={<ViewList />} />
                            <Route path='/credit-history-detail' element={<ListProfiles />} />
                            <Route path='/favorites' element={<ListProfiles />} />
                            <Route path='/notes' element={<Notes />} />
                            <Route path='/credit-history' element={<CreditHistory />} />
                            <Route path='/contact-by-url' element={<GetContactByUrl />} />
                            <Route path='/search-history' element={<SearchHistory />} />
                            <Route path='/user-logs/:id' element={<UserDetails />} />
                            <Route path='/view-profile/:id' element={<ViewProfile />} />
                            <Route path='/settings/billing/cancel-subscription' element={<CancelSubscription />} />
                            <Route path='/settings/billing/cancel-subscription-success' element={<CancelSubscriptionSuccess />} />
                            <Route path='/*' element={<NotFound />} />
                        </>}
                    </Routes>
                </Page.Body>
                <Footer />
            </Page>
        </Suspense>
    );
};

export default App;

