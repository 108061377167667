import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// contexts
import { useBackend } from 'contexts/backend';

// components
import { Card, Col, VStack, HStack, Button } from '@avena/components';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Loading from 'components/loading';

// icons
import searchesIcon from 'assets/saved-search.svg';
import payroll from './assets/payroll.webp';

// styles
import styles from './saved-searches.module.scss';

// helpers
import { getSavedSearches, deleteSearchById, editSearchById } from 'helpers/requests/saved-search';
import EditSearchDialog from 'dialogs/edit-saved-search';
import ConfirmDialog from 'components/confirm-dialog';

const SavedSearches = ({ className, ...props }) => {
    const confirmRef = useRef();
    const navigate = useNavigate();
    const backend = useBackend();
    const [loadingVisible, setLoadingVisible] = useState(true);
    const [searches, setSearches] = useState([]);
    const [editSearchDialogVisible, setEditSearchDialogVisible] = useState(false);
    const [currentSearch, setCurrentSearch] = useState({ name: '' });
    const handleEditSearchDialogClose = () => {
        setEditSearchDialogVisible(false);
        setCurrentSearch({ name: '' });
    };

    const handleEditSearchClick = (e, search) => {
        e.stopPropagation();
        setEditSearchDialogVisible(true);
        setCurrentSearch(search);
    };

    const handleDeleteSearchClick = async (e, search) => {
        e.stopPropagation();
        await setCurrentSearch(search);
        confirmRef.current.confirmClick();
    };

    const handleDeleteConfirm = () => {
        const { _id } = currentSearch;
        deleteSearchById(backend, _id);
        setSearches(prevState => prevState.filter(search => search._id !== _id));
        setCurrentSearch({ name: '' });
    };

    const handleDeleteCancel = () => {
        setCurrentSearch({ name: '' });
    };

    const handleSearchChange = e => {
        setCurrentSearch(prevState => ({ ...prevState, [e.name]: e.value }));
    };

    const handleSearchSave = async () => {
        await editSearchById(backend, currentSearch, currentSearch?._id);
        setSearches(prevState => prevState.map(search => {
            if (search._id === currentSearch._id) {
                return currentSearch;
            }
            return search;
        }));
        setEditSearchDialogVisible(false);
        setCurrentSearch({ name: '' });
    };

    const handleCardClick = (e, search) => {
        const query = new URLSearchParams('');
        query.set('keywords', search.filter);
        navigate(`/search-profile?page=1&${query.toString()}`);
    };

    const getColor = ind => {
        const colors = {
            0: '#241A38',
            1: '#826CB2',
            2: '#7456B7',
            3: '#33274E',
            4: '#553E85',
        };
        return { background: colors[ind % 5] };
    };

    useEffect(() => {
        (async () => {
            setLoadingVisible(true);
            const res = await getSavedSearches(backend);
            setSearches(res);
            setLoadingVisible(false);
        })();
    }, [backend]);

    return (
        <div className={[styles.container, className].filter(Boolean).join(' ')} style={{ gap: '50px' }} {...props}>
            <Col lg={18} xl={18} className={styles.content} >
                <div className={styles.header}>
                    <div>
                        <h2>
                            Saved Searches
                        </h2>
                        <div className={styles.description}>Add your previously filtered search as a draft with one click, and save your precious time.</div>
                    </div>
                    <div>
                        <img src={searchesIcon} alt="searchesIcon" />
                    </div>
                </div>
                <div className={styles.divider} />
                <VStack style={{ gap: '20px', height: '100%' }}>
                    {searches.length ?
                        searches.map((search, ind) => (
                            <Card key={search._id} className={styles.card} style={getColor(ind)} onClick={e => handleCardClick(e, search)}>
                                <HStack className={styles.hstack}>
                                    <VStack className={styles.leftStack}>
                                        <div className={styles.title}>{search.name}</div>
                                        <div className={styles.body}>{search.description}</div>
                                        {/* <HStack style={{ alignItems: 'center', gap: '10px' }}>
                                            <div className={styles.body}>Talents</div>
                                        </HStack> */}
                                    </VStack>
                                    <HStack style={{ gap: '8px' }} className={styles.rightStack}>
                                        {/* <Card className={styles.iconContainer}>
                                            <ShareAltOutlined className={styles.icon} />
                                        </Card> */}
                                        <Card className={styles.iconContainer} onClick={e => handleEditSearchClick(e, search)}>
                                            <EditOutlined className={styles.icon} />
                                        </Card>
                                        <Card className={styles.iconContainer} onClick={e => handleDeleteSearchClick(e, search)}>
                                            <DeleteOutlined className={styles.icon} />
                                        </Card>
                                    </HStack>
                                </HStack>
                            </Card>)
                        ) :
                        <VStack className={styles.imageContainer}>
                            <img className={styles.image} src={payroll} alt="find job" />
                            <div className={styles.noResults}>No saved searches yet.</div>
                            <div className={styles.bodyText}>Start searching for the talent, and make sure that you save the search as a template.</div>
                            <Button className={styles.button} onClick={() => navigate('/search-profile?page=1')}>New Search</Button>
                        </VStack>
                    }
                </VStack>
            </Col>
            <EditSearchDialog visible={editSearchDialogVisible} onClose={handleEditSearchDialogClose} savedSearch={currentSearch} onChange={handleSearchChange} onSave={handleSearchSave} />
            <Loading visible={loadingVisible} />
            <ConfirmDialog
                ref={confirmRef}
                title={'Delete this saved search'}
                content={`Are you sure you want to delete your saved search "${currentSearch?.name}"?`}
                onOk={handleDeleteConfirm}
                onCancel={handleDeleteCancel}
            />
        </div>
    );
};

export default SavedSearches;
