// import config from 'config/config.json';
import verifyToken from './verify-token';
import decode from 'jwt-decode';
import checkExpired from './check-expired';

class Backend {
    constructor(apiURL) {
        this.apiURL = apiURL;
        this.accessToken = null;
        this.accessTokenWorker = null;
    }

    getItem(key) {
        const json = localStorage.getItem(key);
        if (json == null) {
            return null;
        }

        try {
            return JSON.parse(json);
        }
        catch (error) {
            /* do nothing */
        }

        return null;
    }

    setItem(key, value) {
        if (value == null) {
            localStorage.removeItem(key);
            return;
        }

        localStorage.setItem(key, JSON.stringify(value));
    }

    async getAccessToken(signOutIfNotAuthorized = true) {
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            return null;
        }

        if (!verifyToken(refreshToken)) {
            if (!signOutIfNotAuthorized) {
                return null;
            }
            // This code still requires navigate() to work properly. Right now it requeires user to refresh page.
            this.signOut();
        }

        const accessToken = this.accessToken;
        if (verifyToken(accessToken)) {
            this.setSubscripion(accessToken);
            return accessToken;
        }

        const user = this.getItem('user');
        if (!user) {
            return null;
        }

        if (!this.accessTokenWorker) {
            this.accessTokenWorker = this.doGetAccessToken({
                refreshToken,
                user
            });
        }

        return this.accessTokenWorker;
    }

    async doGetAccessToken(params) {
        const response = await fetch(this.getURL('/auth/token/refresh'), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        });

        const data = await response.json();
        this.setAccessToken(data.accessToken);
        this.accessTokenWorker = null;

        this.setSubscripion(data.accessToken);
        return data.accessToken;
    }

    getRefreshToken() {
        const cookieValue = document.cookie
            ?.split('; ')
            ?.find(row => row.startsWith('refreshToken='))
            ?.split('=')
            ?.[1];
        return cookieValue;
    }

    setSubscripion(accessToken) {
        if (accessToken) {
            this.setItem('numberOfCredits', decode(accessToken)?.credits);
            this.setItem('subscription', decode(accessToken)?.stripeInfo);
            this.setItem('isExpired', checkExpired(decode(accessToken)?.stripeInfo?.currentPeriodEnd));
        }
    }

    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }

    setRefreshToken(refreshToken) {
        const year = new Date().getFullYear();
        document.cookie = `refreshToken=${refreshToken}; expires= 31 Dec ${year} 23:59:59 GMT; SameSite=None; Secure;`;
    }

    deleteRefreshToken() {
        document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    }

    getURL(url, params) {
        let result = `${this.apiURL}${url}`;

        if (params) {
            result += `?${new URLSearchParams(params)}`;
        }

        return result;
    }

    async getHeaders(authorized) {
        let headers = {
            'Content-Type': 'application/json'
        };

        if (authorized) {
            const token = await this.getAccessToken(authorized !== 'optional');
            if (token) {
                headers = {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                };
            }
        }

        return headers;
    }

    async signIn(params) {
        const data = await this.post('/auth/sign-in', params, 'json', false);
        this.setItem('user', data.user);
        if (data.credits) {
            this.setItem('credits', data.credits);
        }
        this.setAccessToken(data.accessToken);
        this.setRefreshToken(data.refreshToken);
        this.setSubscripion(data.accessToken);

        return data;
    }

    async signUp(params) {
        const data = await this.post('/users/sign-up', params, 'json', false);
        this.setRefreshToken(data.refreshToken);
        this.setAccessToken(data.accessToken);
        this.setItem('user', data.user);
        if (data.credits) {
            this.setItem('credits', data.credits);
        }
        this.setSubscripion(data.accessToken);

        return data;
    }

    async signOut() {
        this.setAccessToken(null);
        this.setRefreshToken(null);
        this.setItem('user');
        this.setItem('isExpired', true);
        localStorage.clear();
        window.location.href = '/sign-in';
    }

    async get(url, params, contentType = 'json', authorized = true, signal) {
        const response = await fetch(this.getURL(url, params), {
            method: 'GET',
            headers: await this.getHeaders(authorized),
            signal
        });

        if (!response.ok) {
            const { name, message } = await response.json();
            throw new Error([name, message].filter(x => x).join(': '));
        }

        let data = null;
        if (contentType === 'blob') {
            data = await response.blob();
        }
        else if (contentType === 'text') {
            data = await response.text();
        }
        else {
            data = await response.json();
        }

        return data;
    }

    async post(url, params, contentType = 'json', authorized = true) {
        const response = await fetch(this.getURL(url), {
            method: 'POST',
            headers: await this.getHeaders(authorized),
            body: params ? JSON.stringify(params) : null
        });

        if (!response.ok) {
            const { name, message } = await response.json();
            throw new Error([name, message].filter(x => x).join(': '));
        }

        let data = null;
        if (contentType === 'blob') {
            data = await response.blob();
        }
        else if (contentType === 'text') {
            data = await response.text();
        }
        else {
            data = await response.json();
        }

        return data;
    }

    async delete(url, params, contentType = 'json', authorized = true) {
        const response = await fetch(this.getURL(url), {
            method: 'DELETE',
            headers: await this.getHeaders(authorized),
            body: params ? JSON.stringify(params) : null
        });

        if (!response.ok) {
            const { name, message } = await response.json();
            throw new Error([name, message].filter(x => x).join(': '));
        }

        let data = null;
        if (contentType === 'blob') {
            data = await response.blob();
        }
        else if (contentType === 'text') {
            data = await response.text();
        }
        else {
            data = await response.json();
        }

        return data;
    }
}

export default Backend;
