// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header_header__L39fT {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 16px 0px; }\n", "",{"version":3,"sources":["webpack://./src/components/page/header/header.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB,EAAA","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 16px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header_header__L39fT"
};
export default ___CSS_LOADER_EXPORT___;
