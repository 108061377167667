import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

// contexts
import { BackendProvider } from 'contexts/backend';

// components
import App from 'app';
import RouteChangeTracker from 'route-change-tracker';

// styles
import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

// initialize ReactGA
ReactGA.initialize('G-3LWP8S1J21');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <RouteChangeTracker />
        <BackendProvider>
            <App />
        </BackendProvider>
    </BrowserRouter>,
);
