import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Popover } from 'antd';
import { Col } from '@avena/components';

// contexts
import { useBackend } from 'contexts/backend';

// assets
import cogIcon from 'assets/setting.svg';
import logOutIcon from 'assets/log-out.svg';
import docsIcon from 'assets/docs.svg';
import HeartSvg from 'assets/svg/heart-svg.js';
import NoteSvg from 'assets/svg/notes.js';
import listIcon from 'assets/lists2.svg';
import searchHistoryIcon from 'assets/search-history2.svg';
import adminIcon from 'assets/admin.png';

// styles
import styles from './top-level-menu.module.scss';

// helpers
import { createUserLog } from 'helpers/requests/user-log';

const TopLevelMenu = ({ isMenuOpen, setIsMenuOpen, children, signOutClick }) => {
    const backend = useBackend();
    const user = backend.getItem('user');
    const isExpired = backend.getItem('isExpired');
    const navigate = useNavigate();

    const handlePhotoClick = () => {
        setIsMenuOpen(false);
        if (!isExpired) {
            return navigate('/');
        }
        return navigate('/billing-address');
    };

    const content = (
        <div className={styles.container}>
            <div className={styles.title_container}>
                <Col onClick={handlePhotoClick} lg={9} xl={9} style={{ justifyContent: 'center' }}>
                    <div className={styles.name}>
                        <div>{user?.firstName || ''}</div> &nbsp;
                        <div>{user?.lastName || ''}</div>
                    </div>
                    {/* <div className={styles.title}>{""}</div> */}
                </Col>
            </div>
            <div className={styles.divider} />
            <div className={styles.content_container}>
                <div onClick={() => {
                    setIsMenuOpen(false); navigate('/lists');
                    createUserLog(backend, { action: 'topLevelMenu.lists' });
                }} className={styles.menu_item} aria-hidden="true">
                    <img className={styles.icon} src={listIcon} alt="listsIcon" />
                    <div className={styles.item}>Lists</div>
                </div>
                <div onClick={() => {
                    setIsMenuOpen(false); navigate('/favorites');
                    createUserLog(backend, { action: 'topLevelMenu.favorites' });
                }} className={styles.menu_item} aria-hidden="true">
                    <div className={styles.icon}>
                        <HeartSvg fill={'#404040'} width='16' height='16' />
                    </div>
                    <div className={styles.item}>Favorites</div>
                </div>
                <div onClick={() => {
                    setIsMenuOpen(false); navigate('/notes');
                    createUserLog(backend, { action: 'topLevelMenu.notes' });
                }} className={styles.menu_item} aria-hidden="true">
                    <div className={styles.icon}>
                        <NoteSvg fill={'#404040'} width='16' height='16' />
                    </div>
                    <div className={styles.item}>Notes</div>
                </div>
                <div onClick={() => {
                    setIsMenuOpen(false); navigate('/search-history');
                    createUserLog(backend, { action: 'topLevelMenu.searchHistory' });
                }} className={styles.menu_item} aria-hidden="true">
                    <img className={styles.icon} src={searchHistoryIcon} alt="search-history" />
                    <div className={styles.item}>Search History</div>
                </div>
                <div className={styles.divider} />
                <div onClick={() => {
                    setIsMenuOpen(false); navigate('/settings/account');
                    createUserLog(backend, { action: 'topLevelMenu.settings' });
                }} className={styles.menu_item} aria-hidden="true">
                    <img className={styles.icon} src={cogIcon} alt="cog" />
                    <div className={styles.item}>Settings</div>
                </div>
                <div onClick={() => {
                    setIsMenuOpen(false); navigate('/legal/terms-of-service');
                    createUserLog(backend, { action: 'topLevelMenu.agreements' });
                }} className={styles.menu_item} aria-hidden="true">
                    <img className={styles.icon} src={docsIcon} alt="cog" />
                    <div className={styles.item}>Agreements</div>
                </div>
                {
                    user.isAdmin &&
                    <div onClick={() => {
                        setIsMenuOpen(false); navigate('/u-f33c47fe-c69e-41bc-b4fe-5ecf591089a4');
                    }} className={styles.menu_item} aria-hidden="true">
                        <img className={styles.icon} src={adminIcon} alt="adminIcon" />
                        <div className={styles.item}>Admin Dashboard</div>
                    </div>
                }
                <div className={styles.divider} />
                <div onClick={() => {
                    setIsMenuOpen(false); signOutClick();
                    createUserLog(backend, { action: 'user.signOut' });
                }} className={styles.menu_item} aria-hidden="true">
                    <img className={styles.icon} src={logOutIcon} alt="cog" />
                    <div className={styles.agreement}>Log Out</div>
                </div>
            </div>
        </div>
    );

    return (
        <Popover
            onVisibleChange={newVisible => {
                setIsMenuOpen(newVisible);
            }}
            visible={isMenuOpen}
            placement="bottomRight"
            content={content}
            trigger="click"
        >
            {children}
        </Popover>
    );
};

export default TopLevelMenu;
