/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// components
import Page from 'components/page';
import { HStack, VStack } from '@avena/components';
import Logo from 'components/logo';
import inIcon from 'assets/in.webp';
import { useMediaQuery } from 'react-responsive';

// styles
import styles from './footer.module.scss';

const Footer = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const pathList = ['/', '/contact-us', '/upgrade-plan'];
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const query = new URLSearchParams(location.search);

    const handleLinkedInClick = () => {
        window.open('https://www.linkedin.com/company/avena-network/', '_blank');
    };

    const setQuery = searchValue => {
        const searchData = JSON.stringify({
            firstName: [],
            lastName: [],
            title: [{ value: searchValue }],
            about: [],
            quickSearchValue: [],
            'education.institution': [],
            'employment.institution': [],
            'projects.title': [],
            countryCode: [],
            regionCode: [],
            languages: [],
        });
        query.set('keywords', searchData);
        return `/search-profile?${query.toString()}`;
    };

    return (
        <Page.Footer className={styles.footer} style={pathList.includes(pathname) || pathname.startsWith('/legal') ? { background: 'white' } : {}}>
            {pathList.includes(pathname) || pathname.startsWith('/legal') ?
                isMobile ?
                    <VStack>
                        <HStack style={{ gap: '13px', alignItems: 'center' }}>
                            <Logo fill='#0066cc' width='99.52' height='43.24' />
                            {/* <VStack className={styles.connectContainer}>
                                <div className={styles.connect}>Connecting you to the</div>
                                <div className={styles.connect}>world’s top professionals</div>
                            </VStack> */}
                        </HStack>
                        <div className={styles.hrSolid} />
                        <VStack className={styles.rightStack}>
                            <span>Company</span>
                            <Link to='/legal/terms-of-service'><div>Terms of Service</div></Link>
                            <Link to='/legal/privacy-policy'><div>Privacy Policy</div></Link>
                            <Link to='/legal/cookie-policy'><div>Cookie Policy</div></Link>
                            <Link to='/contact-us'><div>Contact Us</div></Link>
                            <div className={styles.hrDashed} />
                            <span>Our Expertise</span>
                            <Link onClick={setQuery('Web')}><div>Web Developers</div></Link>
                            <Link to={setQuery('Mobile Developer')}><div>Mobile Developers</div></Link>
                            <Link to={setQuery('Hybrid Developer')}><div>Hybrid Developers</div></Link>
                            <Link to={setQuery('Artifical Intelligenc')}><div>Artifical Intelligence</div></Link>
                            <Link to={setQuery('Product Designer')}><div>Product Designers</div></Link>
                            <Link to={setQuery('Engineer')}><div>Engineers</div></Link>
                            <Link to={setQuery('Quality Assuranc')}><div>Quality Assurance</div></Link>
                            <Link to={setQuery('Cloud Based Engineer')}><div>Cloud Based Engineers</div></Link>
                            <Link to={setQuery('UI/UX Designer')}><div>UI/UX Designers</div></Link>
                        </VStack>
                        <div className={styles.hrDashed} />
                        <HStack className={styles.inContainer} style={{ marginTop: '0px' }}>
                            <img onClick={handleLinkedInClick} src={inIcon} alt="" style={{ width: '22px', height: '22px', cursor: 'pointer' }}/>
                            <div onClick={handleLinkedInClick} className={styles.linkedin}>Linkedin</div>
                        </HStack>
                        <a href='mailto:info@avena.co'><div className={styles.info}>info@avena.co</div></a>
                        <div className={styles.copyright}>Copyright © 2022 Talenthub. All rights reserved.</div>
                    </VStack> :
                    <HStack className={styles.hstack}>
                        <VStack>
                            <HStack style={{ gap: '13px' }}>
                                <Logo fill='#0066cc' width='138.64' height='60.24   ' />
                                {/* <VStack className={styles.connectContainer}>
                                    <div className={styles.connect}>Connecting you to the</div>
                                    <div className={styles.connect}>world’s top professionals</div>
                                </VStack> */}
                            </HStack>
                            <HStack className={styles.inContainer}>
                                <img onClick={handleLinkedInClick} src={inIcon} alt="" style={{ width: '22px', height: '22px', cursor: 'pointer' }} />
                                <div onClick={handleLinkedInClick} className={styles.linkedin}>Linkedin</div>
                            </HStack>
                            <a href='mailto:info@avena.co'><div className={styles.info}>info@avena.co</div></a>
                            <div className={styles.copyright}>Copyright © 2022 Talenthub. All rights reserved.</div>
                        </VStack>
                        <HStack style={{ gap: '80px' }} className={styles.rightStack}>
                            <VStack >
                                <span>Company</span>
                                <Link to='/legal/terms-of-service'><div>Terms of Service</div></Link>
                                <Link to='/legal/privacy-policy'><div>Privacy Policy</div></Link>
                                <Link to='/legal/cookie-policy'><div>Cookie Policy</div></Link>
                                <Link to='/contact-us'><div>Contact Us</div></Link>
                                <a href='mailto:info@avena.co'><div>info@avena.co</div></a>
                            </VStack>
                            <VStack>
                                <span>Our Expertise</span>
                                <Link to={setQuery('Web Developer')}><div>Web Developers</div></Link>
                                <Link to={setQuery('Mobile Developer')}><div>Mobile Developers</div></Link>
                                <Link to={setQuery('Hybrid Developer')}><div>Hybrid Developers</div></Link>
                                <Link to={setQuery('Artifical Intelligenc')}><div>Artifical Intelligence</div></Link>
                                <Link to={setQuery('Product Designer')}><div>Product Designers</div></Link>
                            </VStack>
                            <VStack>
                                <span></span>
                                <Link to={setQuery('Engineer')}><div>Engineers</div></Link>
                                <Link to={setQuery('Quality Assuranc')}><div>Quality Assurance</div></Link>
                                <Link to={setQuery('Cloud Based Engineer')}><div>Cloud Based Engineers</div></Link>
                                <Link to={setQuery('UI/UX Designer')}><div>UI/UX Designers</div></Link>
                            </VStack>
                        </HStack>
                    </HStack> :
                <div className={styles.top_bar}>
                    <div className={styles.brand} style={{ alignSelf: 'baseline' }}>
                        <Logo fill='#0066cc' width='91.36' height='39.69    ' />
                    </div>
                    <div className={styles.icon_links}>
                        {/* <div className={styles.group}>
                            <div to='#' className={styles.avena2021}>© 2021 Talenthub® </div>
                        </div> */}
                        <div className={styles.group}>
                            <Link to='/legal/terms-of-service'>Terms of Service</Link>
                            <div className={styles.divider}>|</div>
                            <Link to='/legal/privacy-policy'>Privacy Policy</Link>
                            <div className={styles.divider}>|</div>
                            <Link to='/legal/terms-of-use'>Terms of Use</Link>
                            <div className={styles.divider}>|</div>
                            <Link to='/contact-us'>Contact Us</Link>
                        </div>
                    </div>
                </div>
            }
        </Page.Footer>
    );
};

export default Footer;
