// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".page_page__gPLLk {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0; }\n", "",{"version":3,"sources":["webpack://./src/components/page/page.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB;EACtB,SAAa,EAAA","sourcesContent":[".page {\n    display: flex;\n    flex-direction: column;\n    flex: 1 0 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "page_page__gPLLk"
};
export default ___CSS_LOADER_EXPORT___;
