/* eslint-disable no-nested-ternary */
import React, { useState, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// contexts
import { useBackend } from 'contexts/backend';

// components
import {
    Button,
    HStack,
    VStack,
    Card,
    Spacer
} from '@avena/components';
// import moment from 'moment-timezone';

import Logo from 'components/logo';
import Page from 'components/page';
import TopLevelMenu from 'components/top-level-menu';
import { MenuOutlined } from '@ant-design/icons';
import CloseSvg from 'assets/svg/close-svg';

// assets
import UserIcon from 'assets/svg/user-icon-svg';
import SearchIcon from 'assets/svg/search';
import SavedSearchIcon from 'assets/svg/saved-search';
import FavoriteIcon from 'assets/svg/heart-svg';
import ListIcon from 'assets/svg/list';
import NoteIcon from 'assets/svg/notes';
import HomeIcon from 'assets/svg/home';
import SearchHistory from 'assets/svg/search-history';

// styles
import styles from './header.module.scss';
import MobileLogo from 'components/logo/mobile';

const Header = ({ style }) => {
    const backend = useBackend();
    const user = backend.getItem('user');
    const photo = user?.photo;
    const numberOfCredits = backend.getItem('numberOfCredits');

    const navigate = useNavigate();
    const isExpired = backend.getItem('isExpired');
    // const subscription = backend.getItem('subscription');

    // const subscriptionLeftInDays = moment(moment.unix(subscription?.currentPeriodEnd)).diff(moment(Date.now()), 'days');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [currentHover, setCurrentHover] = useState(false);
    const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);

    const handleUserIconClick = () => {
        setIsMenuOpen(true);
    };

    const MenuIcon = () => (
        <TopLevelMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} signOutClick={() => backend.signOut(navigate)} photo={photo}>
            {photo ?
                <button onClick={handleUserIconClick} className={styles.ppContainer}>
                    <img src={photo} alt='' className={styles.userIcon} />
                </button> :
                <UserIcon fill={style === 'light' ? '#0066cc' : '#ffffff'} onClick={handleUserIconClick} />
            }
        </TopLevelMenu>
    );

    const handleHamburgerMenuClick = () => {
        setHamburgerMenuVisible(prevState => !prevState);
    };
    const handleHomeClick = () => {
        navigate('/home');
        setHamburgerMenuVisible(prevState => !prevState);
    };

    const handleHover = ind => {
        setTimeout(() => {
            setCurrentHover(ind);
        }, 80);
    };

    useLayoutEffect(() => {
        // if search menu closed and screen is greater than 1023 show searchBlock menu
        function updateSize() {
            if (window.innerWidth > 700) {
                setIsMobile(false);
            }
            else {
                setIsMobile(true);
            }
        }
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <Page.Header className={[styles.header, styles[style]].filter(Boolean).join(' ')}>
            <Card className={styles.hamburgerMenu} style={(hamburgerMenuVisible && isMobile) ? { width: '100%', gap: '0px' } : null}>
                {hamburgerMenuVisible && isMobile ?
                    <VStack className={styles.VStack}>
                        <VStack>
                            <HStack className={styles.topStack}>
                                <Logo width='70' height='auto' onClick={handleHomeClick} />
                                <CloseSvg fill="#7C7B7B" width='16' height='16' onClick={handleHamburgerMenuClick} />
                            </HStack>
                            <hr className={styles.hr} />
                            <Link to='/search-profile?page=1' onClick={handleHamburgerMenuClick}><HStack className={styles.itemContainer}>
                                <SearchIcon fill='#404040' width='18' height='18' />
                                <div>New search</div>
                            </HStack></Link>
                            <Link to='/saved-searches' onClick={handleHamburgerMenuClick}><HStack className={styles.itemContainer}>
                                <SavedSearchIcon fill='#404040' width='18' height='18' />
                                <div>Saved search</div>
                            </HStack></Link>
                            <Link to='/favorites' onClick={handleHamburgerMenuClick}><HStack className={styles.itemContainer}>
                                <FavoriteIcon fill='#404040' width='18' height='18' />
                                <div>Favorites</div>
                            </HStack></Link>
                            <Link to='/lists' onClick={handleHamburgerMenuClick}><HStack className={styles.itemContainer}>
                                <ListIcon fill='#404040' width='18' height='18' />
                                <div>Lists</div>
                            </HStack></Link>
                            <Link to='/notes' onClick={handleHamburgerMenuClick}><HStack className={styles.itemContainer}>
                                <NoteIcon fill='#404040' width='18' height='18' />
                                <div>Notes</div>
                            </HStack></Link>
                            <Link to='/search-history' onClick={handleHamburgerMenuClick}><HStack className={styles.itemContainer}>
                                <SearchHistory fill='#404040' width='18' height='18' />
                                <div>Search History</div>
                            </HStack></Link>
                        </VStack>
                        <VStack>
                            <div className={styles.bottomLogoStack}>
                                <HStack style={{ paddingLeft: '14px', alignItems: 'center' }}>
                                    <Logo width='70' height='auto' onClick={handleHomeClick} />
                                    <VStack className={styles.bottomTextStack}>
                                        <div>Meet your next great hire...</div>
                                        <div>in minutes!</div>
                                    </VStack>
                                </HStack>
                            </div>
                            <HStack style={{ justifyContent: 'space-between', width: '100%', padding: '14px' }}>
                                <Link to='legal/terms-of-service' className={styles.link} onClick={handleHamburgerMenuClick}>Terms of Service</Link>
                                <Link to='legal/privacy-policy' className={styles.link} onClick={handleHamburgerMenuClick}>Privacy Policy</Link>
                                <Link to='legal/cookie-policy' className={styles.link} onClick={handleHamburgerMenuClick}>Cookie Policy</Link>
                                <Link to='contact-us' className={styles.link} onClick={handleHamburgerMenuClick}>Contact Us</Link>
                            </HStack>
                            <div className={styles.bottomBackground} />
                        </VStack>
                    </VStack> : null
                }
            </Card>
            <HStack className={styles.headerContent}>
                {isMobile && user && <MenuOutlined
                    style={style === 'light' ?
                        { color: '#0066cc', fontSize: '30px' } :
                        { color: '#ffffff', fontSize: '30px' }}
                    onClick={handleHamburgerMenuClick}
                />}
                <Link to={user ? '/home' : '/'} style={{ display: 'flex' }}>
                    {isMobile ?
                        <MobileLogo fill={style === 'light' ? null : 'white'} /> :
                        <Logo fill={style === 'light' ? null : 'white'} width='139' height='60' />
                    }
                </Link>
                {user ? <>
                    <Link to='/home' className={styles.desktopLink} onMouseEnter={() => handleHover(1)} onMouseLeave={() => handleHover(0)}>
                        <HStack style={{ gap: '10px', alignItems: 'center' }}>
                            <HomeIcon width='22' height='22' fill={currentHover === 1 ? '#002f5e' : style === 'light' ? '#0066cc' : '#ffffff'} />
                            <div>Home</div>
                        </HStack>
                    </Link>
                    <Link to='/search-profile?page=1' className={styles.desktopLink} onMouseEnter={() => handleHover(2)} onMouseLeave={() => handleHover(0)}>
                        <HStack style={{ gap: '10px', alignItems: 'center' }}>
                            <SearchIcon width='16' height='16' fill={currentHover === 2 ? '#002f5e' : style === 'light' ? '#0066cc' : '#ffffff'} />
                            <div>New search</div>
                        </HStack>
                    </Link>
                    <Link to='/saved-searches' className={styles.desktopLink} onMouseEnter={() => handleHover(3)} onMouseLeave={() => handleHover(0)}>
                        <HStack style={{ gap: '10px', alignItems: 'center' }}>
                            <SavedSearchIcon width='16' height='16' fill={currentHover === 3 ? '#002f5e' : style === 'light' ? '#0066cc' : '#ffffff'} />
                            <div>Saved search</div>
                        </HStack>
                    </Link>
                    <Link to='/favorites' className={styles.desktopLink} onMouseEnter={() => handleHover(4)} onMouseLeave={() => handleHover(0)}>
                        <HStack style={{ gap: '10px', alignItems: 'center' }}>
                            <FavoriteIcon width='16' height='16' fill={currentHover === 4 ? '#002f5e' : style === 'light' ? '#0066cc' : '#ffffff'} />
                            <div>Favorites</div>
                        </HStack>
                    </Link>
                    <Link to='/lists' className={styles.desktopLink} onMouseEnter={() => handleHover(5)} onMouseLeave={() => handleHover(0)}>
                        <HStack style={{ gap: '10px', alignItems: 'center' }}>
                            <ListIcon width='16' height='16' fill={currentHover === 5 ? '#002f5e' : style === 'light' ? '#0066cc' : '#ffffff'} />
                            <div>Lists</div>
                        </HStack>
                    </Link>
                    <Spacer />
                    {/* {subscription?.status === 'trialing' && isExpired === false && <Card className={`${styles.trialCard} ${style === 'light' && styles.light}`} onClick={() => navigate('/upgrade-plan')}>{subscriptionLeftInDays} trial days left  |  Subscribe now</Card>} */}
                    {!isExpired && numberOfCredits === 0 &&
                        <Card className={`${styles.trialCard} ${style === 'light' && styles.light}`} onClick={() => navigate('/add-credits')}>Buy Credits</Card>
                    }
                    {isExpired && <Card className={`${styles.trialCard} ${style === 'light' && styles.light}`} onClick={() => navigate('/upgrade-plan')}>Upgrade plan</Card>}
                    <MenuIcon />
                </> : <>
                    <Spacer />
                    <Button style={{ background: 'white' }} type='secondary' onClick={() => navigate('/sign-in')}>Sign In</Button>
                    <Button onClick={() => navigate('/sign-up')}>Sign Up</Button>
                </>}
            </HStack>
        </Page.Header >
    );
};

export default Header;
