import { toast, Slide } from 'react-toastify';

const notify = (type, message) => {
    toast[type](message, {
        position: 'top-right',
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        transition: Slide,
    });

    if (type === 'error') {
        console.error(message);
    }
    else {
        console.log(message);
    }
};

export default notify;
