import { apiURL } from 'service';

// helpers
import notify from 'helpers/notify';

const createSavedSearch = async(backend, data) => {
    try {
        const response = await backend.post(apiURL.savedSearch, data);
        return response;
    }
    catch (error) {
        notify('error', error.message);
        throw error;
    }
};

const editSearchById = async(backend, data, id) => {
    try {
        const response = await backend.post(`${apiURL.savedSearch}/${id}`, data);
        return response;
    }
    catch (error) {
        notify('error', error.message);
        throw error;
    }
};

const deleteSearchById = async(backend, id) => {
    try {
        const response = await backend.delete(`${apiURL.savedSearch}/${id}`);
        return response;
    }
    catch (error) {
        notify('error', error.message);
        throw error;
    }
};

const getSavedSearches = async backend => {
    try {
        return await backend.get(apiURL.savedSearch);
    }
    catch (error) {
        notify('error', error.message);
        throw error;
    }
};

export { createSavedSearch, getSavedSearches, editSearchById, deleteSearchById };
