// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_main_cont__pbTSz .loading_gradient_spinner__MaMTd {\n  width: 64px;\n  height: 64px;\n  background: linear-gradient(180deg, #0f6fff 0%, rgba(0, 0, 0, 0.1) 25%);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  animation: loading_spin__OW\\+R2 1s linear infinite; }\n\n.loading_main_cont__pbTSz .loading_gradient_spinner__MaMTd::after {\n  content: \"\";\n  width: calc(100% - 4px);\n  height: calc(100% - 4px);\n  border-radius: 50%;\n  background: #f9fafa; }\n\n@keyframes loading_spin__OW\\+R2 {\n  100% {\n    transform: rotate(360deg); } }\n", "",{"version":3,"sources":["webpack://./src/components/loading/loading.module.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAW;EACX,YAAY;EACZ,uEAAuE;EACvE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,kDAAkC,EAAA;;AAV1C;EAcQ,WAAW;EACX,uBAAuB;EACvB,wBAAwB;EACxB,kBAAkB;EAClB,mBAAmB,EAAA;;AAGrB;EACE;IACE,yBAAyB,EAAA,EAAA","sourcesContent":[".main_cont {\n    .gradient_spinner {\n        width: 64px;\n        height: 64px;\n        background: linear-gradient(180deg, #0f6fff 0%, rgba(0, 0, 0, 0.1) 25%);\n        border-radius: 50%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        position: relative;\n        animation: spin 1s linear infinite;\n      }\n\n      .gradient_spinner::after {\n        content: \"\";\n        width: calc(100% - 4px);\n        height: calc(100% - 4px);\n        border-radius: 50%;\n        background: #f9fafa;\n      }\n\n      @keyframes spin {\n        100% {\n          transform: rotate(360deg);\n        }\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_cont": "loading_main_cont__pbTSz",
	"gradient_spinner": "loading_gradient_spinner__MaMTd",
	"spin": "loading_spin__OW+R2"
};
export default ___CSS_LOADER_EXPORT___;
