import React from 'react';

export default function Search({ width = '12', height = '12', fill = 'white', onClick }) {
    return (
        <svg width={width} height={height} onClick={onClick} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.6318 3.8195C10.854 3.66629 11.1477 3.66629 11.3699 3.8195L18.0157 8.40284C18.1917 8.52417 18.2967 8.72423 18.2967 8.93793V15.8129C18.2967 17.1844 17.1849 18.2962 15.8134 18.2962H6.18841C4.81692 18.2962 3.70508 17.1844 3.70508 15.8129V8.93793C3.70508 8.72423 3.81012 8.52417 3.98605 8.40285L10.6318 3.8195ZM11.0009 5.14418L5.00508 9.27924V15.8129C5.00508 16.4664 5.53487 16.9962 6.18841 16.9962H15.8134C16.4669 16.9962 16.9967 16.4665 16.9967 15.8129V9.27925L11.0009 5.14418Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.7704 13.2536C10.1169 13.2536 9.58711 13.7834 9.58711 14.4369V16.9953H12.4121V14.4369C12.4121 13.7834 11.8823 13.2536 11.2287 13.2536H10.7704ZM8.28711 14.4369C8.28711 13.0654 9.39898 11.9536 10.7704 11.9536H11.2287C12.6003 11.9536 13.7121 13.0654 13.7121 14.4369V17.6453C13.7121 18.0043 13.4211 18.2953 13.0621 18.2953H8.93711C8.57812 18.2953 8.28711 18.0043 8.28711 17.6453V14.4369Z" fill={fill} />
        </svg>
    );
}
