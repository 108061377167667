import React from 'react';

const MobileLogo = ({ fill, width = '42', height = '38' }) => (
    <svg width={width} height={height} viewBox="0 0 280 254" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H253.334V95.5557H244.445V8.8889H8.8889V244.445H244.445V221.111H253.334V253.334H0V0Z" fill={fill ? fill : 'url(#paint0_linear_9262_130554)'} />
        <path d="M184.094 178.288H164.794V161.184H154.387V206.027H164.794V187.577H184.094V206.027H194.501V161.184H184.094V178.288Z" fill={fill ? fill : 'url(#paint1_linear_9262_130554)'} />
        <path d="M225.694 189.563C225.694 195.072 223.521 197.827 219.239 197.827C215.551 197.827 213.706 195.905 213.706 192.125V173.996H203.694V192.702C203.694 201.991 207.58 206.667 215.287 206.667C220.622 206.667 224.114 204.041 225.694 198.788V206.027H235.707V173.996H225.694V189.563Z" fill={fill ? fill : 'url(#paint2_linear_9262_130554)'} />
        <path d="M265.831 173.356C260.1 173.356 256.28 176.046 254.304 181.427V161.184H244.291V206.027H254.304V198.852C256.214 204.041 259.837 206.667 265.172 206.667C274.394 206.667 279.795 200.133 279.795 190.076C279.795 179.826 274.723 173.356 265.831 173.356ZM262.01 197.827C256.675 197.827 253.974 195.264 253.974 190.076C253.974 184.694 256.543 182.196 262.01 182.196C267.214 182.196 269.783 184.823 269.783 190.076C269.783 195.264 267.214 197.827 262.01 197.827Z" fill={fill ? fill : 'url(#paint3_linear_9262_130554)'} />
        <path d="M84.4443 104.445V113.477H97.6183V149.287H108.026V113.477H121.2V104.445H84.4443Z" fill={fill ? fill : 'url(#paint4_linear_9262_130554)'} />
        <path d="M144.176 124.688C142.2 119.307 138.379 116.616 132.649 116.616C123.756 116.616 118.684 123.086 118.684 133.336C118.684 143.394 124.086 149.928 133.307 149.928C138.643 149.928 142.266 147.301 144.176 142.112V149.287H154.188V117.257H144.176V124.688ZM136.469 141.087C131.265 141.087 128.697 138.525 128.697 133.336C128.697 128.083 131.265 125.457 136.469 125.457C141.936 125.457 144.505 127.955 144.505 133.336C144.505 138.525 141.805 141.087 136.469 141.087Z" fill={fill ? fill : 'url(#paint5_linear_9262_130554)'} />
        <path d="M172.747 149.287V104.445H162.734V149.287H172.747Z" fill={fill ? fill : 'url(#paint6_linear_9262_130554)'} />
        <path d="M212.02 133.016C212.02 128.083 210.571 124.111 207.607 121.101C204.642 118.09 200.69 116.616 195.882 116.616C190.876 116.616 186.858 118.154 183.762 121.293C180.666 124.432 179.151 128.468 179.151 133.528C179.151 138.461 180.732 142.369 183.893 145.38C186.989 148.39 191.139 149.928 196.211 149.928C203.918 149.928 208.99 147.109 211.295 141.408L202.732 138.461C201.151 141.536 199.636 142.497 196.475 142.497C192.259 142.497 190.151 140.703 189.558 136.347H211.954C212.02 135.386 212.02 134.297 212.02 133.016ZM196.079 123.727C200.229 123.727 202.074 125.457 202.139 130.005H189.558C190.085 125.585 192.061 123.727 196.079 123.727Z" fill={fill ? fill : 'url(#paint7_linear_9262_130554)'} />
        <path d="M238.86 116.616C233.525 116.616 230.034 119.243 228.453 124.496V117.257H218.441V149.287H228.453V133.721C228.453 128.211 230.627 125.457 234.908 125.457C238.597 125.457 240.441 127.379 240.441 131.158V149.287H250.453V130.582C250.453 121.293 246.567 116.616 238.86 116.616Z" fill={fill ? fill : 'url(#paint8_linear_9262_130554)'} />
        <path d="M280 117.257H270.91V107.968H260.898V117.257H255.036V125.777H260.898V138.397C260.898 144.867 265.443 149.287 272.096 149.287H279.342V140.767H270.91V125.777H280V117.257Z" fill={fill ? fill : 'url(#paint9_linear_9262_130554)'} />
        <defs>
            <linearGradient id="paint0_linear_9262_130554" x1="222.252" y1="-252.344" x2="-53.2911" y2="-241.151" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint1_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint2_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint3_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint4_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint5_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint6_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint7_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint8_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint9_linear_9262_130554" x1="256.007" y1="2.62166" x2="44.2354" y2="19.0797" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
        </defs>
    </svg>
);

export default MobileLogo;
