import React from 'react';

// components
import Dialog from 'components/dialog';
// components
import {
    Button,
    Input,
} from '@avena/components';
import TextArea from 'components/text-area';

// styles
import styles from './edit-saved-search.module.scss';

function EditSavedSearch({ visible, onClose, savedSearch, onChange, onSave }) {
    return (
        <Dialog size="xs" visible={visible} onClose={onClose}>
            <Dialog.Header onClose={onClose}>{savedSearch?._id ? 'Edit' : 'Create'} Saved Search</Dialog.Header>
            <Dialog.Body>
                <div className={styles.container}>
                    <Input
                        onChange={onChange}
                        value={savedSearch?.name}
                        name='name'
                        label='Search name'
                        style={{ marginBottom: '21px' }}
                    />
                    <TextArea onChange={e => onChange({ name: 'description', value: e.target.value })} value={savedSearch?.description} placeholder='Description (optional)' rows={4} style={{ borderRadius: '6px', background: '#F9FBFD', marginBottom: '50px' }} />
                    <div className={styles.button_container}>
                        <Button onClick={onClose} className={styles.button_custom}>Cancel</Button>
                        <Button onClick={onSave} className={styles.button}>{savedSearch?._id ? 'Update' : 'Save'}</Button>
                    </div>
                </div>
            </Dialog.Body>
        </Dialog>
    );
}

export default EditSavedSearch;
