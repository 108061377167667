import React from 'react';

// components
import AntdTextArea from 'antd/lib/input/TextArea';

// styles
import styles from './text-area.module.scss';

const TextArea = ({ style, children, ...props }) => (
    <div className={styles.container} style={{ width: '100%', ...style }}>
        <AntdTextArea bordered={false} style={{ flex: '1 1 0px' }} {...props}>
            {children}
        </AntdTextArea>
    </div>
);

export default TextArea;
