import handleError from 'helpers/handle-error';
import { apiURL } from 'service';

const createUserLog = (backend, data) => {
    try {
        backend.post(apiURL.createUserLog, data, 'json', 'optional');
    }
    catch (error) {
        handleError(error);
    }
};

export { createUserLog };
