import React from 'react';

export default function UserIcon({ width = '30', height = '30', fill = 'red', onClick }) {
    return (
        <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={{ cursor: 'pointer' }}>
            <circle cx="15" cy="15" r="14" stroke={fill} strokeWidth="2" />
            <path d="M15.0001 14.1179C16.9494 14.1179 18.5295 12.5377 18.5295 10.5885C18.5295 8.63925 16.9494 7.05908 15.0001 7.05908C13.0509 7.05908 11.4707 8.63925 11.4707 10.5885C11.4707 12.5377 13.0509 14.1179 15.0001 14.1179Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0018 7.50052C13.2963 7.50052 11.9136 8.88317 11.9136 10.5888C11.9136 12.2943 13.2963 13.677 15.0018 13.677C16.7074 13.677 18.0901 12.2943 18.0901 10.5888C18.0901 8.88317 16.7074 7.50052 15.0018 7.50052ZM11.0312 10.5888C11.0312 8.39586 12.8089 6.61816 15.0018 6.61816C17.1947 6.61816 18.9724 8.39586 18.9724 10.5888C18.9724 12.7816 17.1947 14.5593 15.0018 14.5593C12.8089 14.5593 11.0312 12.7816 11.0312 10.5888Z" fill={fill} />
            <path d="M9.65201 22.9416H20.3484C21.5337 22.9416 22.4467 21.7864 21.8933 20.5985C21.079 18.8511 19.2227 16.7651 15.0002 16.7651C10.7777 16.7651 8.92145 18.8511 8.10722 20.5985C7.5537 21.7864 8.46672 22.9416 9.65201 22.9416Z" fill={fill} />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0297 17.6609C11.2059 16.8652 12.8144 16.3237 15.0015 16.3237C17.1887 16.3237 18.7972 16.8652 19.9733 17.6609C21.1472 18.4551 21.8615 19.4829 22.2944 20.412L22.2945 20.412C23.0047 21.9364 21.8076 23.3826 20.3497 23.3826H9.6533C8.19539 23.3826 6.99827 21.9364 7.70862 20.412C8.14153 19.4829 8.85586 18.4551 10.0297 17.6609ZM10.5241 18.3917C9.51063 19.0774 8.88971 19.9663 8.50841 20.7847L8.5084 20.7847C8.1117 21.636 8.74062 22.5002 9.6533 22.5002H20.3497C21.2624 22.5002 21.8913 21.636 21.4947 20.7846C21.1133 19.9663 20.4924 19.0774 19.4789 18.3917C18.4677 17.7076 17.0369 17.2061 15.0015 17.2061C12.9661 17.2061 11.5353 17.7076 10.5241 18.3917Z" fill={fill} />
        </svg>

    );
}
