import React from 'react';

const Logo = ({ fill, width = 121, height = 35, onClick }) => (
    <svg width={width} height={height} viewBox="0 0 569 247" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path d="M424.754 115.448H395.765V89.5H380.132V157.528H395.765V129.539H424.754V157.528H440.386V89.5H424.754V115.448Z" fill={fill === 'white' ? '#fff' : 'url(#paint0_linear_9262_130522)'} />
        <path d="M487.239 132.552C487.239 140.91 483.974 145.089 477.543 145.089C472.002 145.089 469.232 142.173 469.232 136.439V108.937H454.193V137.314C454.193 151.406 460.031 158.5 471.607 158.5C479.621 158.5 484.864 154.515 487.239 146.546V157.528H502.278V108.937H487.239V132.552Z" fill={fill === 'white' ? '#fff' : 'url(#paint1_linear_9262_130522)'} />
        <path d="M547.525 107.965C538.917 107.965 533.179 112.046 530.211 120.21V89.5H515.172V157.528H530.211V146.644C533.08 154.515 538.522 158.5 546.536 158.5C560.387 158.5 568.5 148.587 568.5 133.33C568.5 117.78 560.882 107.965 547.525 107.965ZM541.787 145.089C533.773 145.089 529.716 141.201 529.716 133.33C529.716 125.166 533.575 121.376 541.787 121.376C549.603 121.376 553.461 125.361 553.461 133.33C553.461 141.201 549.603 145.089 541.787 145.089Z" fill={fill === 'white' ? '#fff' : 'url(#paint2_linear_9262_130522)'} />
        <path d="M77.5 89.5V103.203H97.2877V157.528H112.92V103.203H132.708V89.5H77.5Z" fill={fill === 'white' ? '#fff' : 'url(#paint3_linear_9262_130522)'} />
        <path d="M167.219 120.21C164.251 112.046 158.512 107.965 149.905 107.965C136.548 107.965 128.93 117.78 128.93 133.33C128.93 148.587 137.043 158.5 150.894 158.5C158.908 158.5 164.35 154.515 167.219 146.644V157.528H182.258V108.937H167.219V120.21ZM155.643 145.089C147.827 145.089 143.968 141.201 143.968 133.33C143.968 125.361 147.827 121.376 155.643 121.376C163.855 121.376 167.714 125.166 167.714 133.33C167.714 141.201 163.657 145.089 155.643 145.089Z" fill={fill === 'white' ? '#fff' : 'url(#paint4_linear_9262_130522)'} />
        <path d="M210.133 157.528V89.5H195.094V157.528H210.133Z" fill={fill === 'white' ? '#fff' : 'url(#paint5_linear_9262_130522)'} />
        <path d="M269.123 132.844C269.123 125.361 266.946 119.335 262.494 114.768C258.042 110.2 252.105 107.965 244.883 107.965C237.363 107.965 231.328 110.297 226.678 115.059C222.028 119.821 219.752 125.944 219.752 133.621C219.752 141.104 222.127 147.032 226.876 151.6C231.526 156.168 237.759 158.5 245.377 158.5C256.953 158.5 264.571 154.224 268.034 145.575L255.172 141.104C252.798 145.769 250.522 147.227 245.773 147.227C239.441 147.227 236.275 144.506 235.385 137.897H269.024C269.123 136.439 269.123 134.787 269.123 132.844ZM245.18 118.752C251.413 118.752 254.183 121.376 254.282 128.276H235.385C236.176 121.57 239.144 118.752 245.18 118.752Z" fill={fill === 'white' ? '#fff' : 'url(#paint6_linear_9262_130522)'} />
        <path d="M309.438 107.965C301.424 107.965 296.18 111.949 293.806 119.918V108.937H278.767V157.528H293.806V133.913C293.806 125.555 297.071 121.376 303.502 121.376C309.042 121.376 311.812 124.292 311.812 130.025V157.528H326.851V129.151C326.851 115.059 321.014 107.965 309.438 107.965Z" fill={fill === 'white' ? '#fff' : 'url(#paint7_linear_9262_130522)'} />
        <path d="M371.231 108.937H357.578V94.8451H342.539V108.937H333.734V121.862H342.539V141.007C342.539 150.823 349.366 157.528 359.359 157.528H370.242V144.603H357.578V121.862H371.231V108.937Z" fill={fill === 'white' ? '#fff' : 'url(#paint8_linear_9262_130522)'} />
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H395V62H380V15H15V232H380V186H395V247H0V0Z" fill={fill === 'white' ? '#fff' : 'url(#paint9_linear_9262_130522)'} />
        <defs>
            <linearGradient id="paint0_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint1_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint2_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint3_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint4_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint5_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint6_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint7_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint8_linear_9262_130522" x1="508.259" y1="20.7695" x2="14.5871" y2="163.48" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
            <linearGradient id="paint9_linear_9262_130522" x1="346.537" y1="-246.035" x2="-81.9923" y2="-218.196" gradientUnits="userSpaceOnUse">
                <stop stopColor="#597EF7" />
                <stop offset="1" stopColor="#CA61A2" />
            </linearGradient>
        </defs>
    </svg>
);

export default Logo;
