import React, { useContext, useRef } from 'react';

// helpers
import Backend from 'helpers/backend';

// config
import config from 'config/config.json';

const BackendContext = React.createContext();

function BackendProvider({ children }) {
    const backend = useRef(new Backend(config.api.host));

    return (
        <BackendContext.Provider value={backend.current}>
            {children}
        </BackendContext.Provider>
    );
}

function useBackend() {
    return useContext(BackendContext);
}

export {
    BackendProvider,
    useBackend
};
