import React, { useEffect } from 'react';

// components
import Modal from 'antd/lib/modal';

// helpers
import usePrevious from 'helpers/use-previous';

// styles
import styles from './dialog.module.scss';

const Header = ({ children, onClose }) => (
    <div className={styles.header}>
        <div className={styles.title}>{children}</div>
        <button onClick={onClose} className={styles.closeButton}>✕</button>
    </div>
);

const Body = ({ children }) => (
    <div className={styles.body}>
        {children}
    </div>
);

const Footer = ({ children }) => (
    <div className={styles.footer}>
        {children}
    </div>
);

const Dialog = ({
    visible,
    children,
    onOpen,
    onClose,
    viewHeight,
    size = '',
}) => {
    const wasVisible = usePrevious(visible);

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        if (visible && !wasVisible) {
            if (onOpen) {
                onOpen();
            }
        }
    }, [visible, wasVisible, onOpen]);

    if (!visible) {
        return null;
    }

    // default size, medium
    let width = 1400;

    if (size === 'xxs') {
        width = 350;
    }
    else if (size === 'xs') {
        width = 450;
    }
    else if (size === 'small') {
        width = 600;
    }
    else if (size === 'large') {
        width = 1200;
    }

    let height = '';

    if (viewHeight === 'small') {
        height = '240px';
    }
    else if (viewHeight === 'xs') {
        height = '160px';
    }
    else if (viewHeight === 'normal') {
        height = '330px';
    }

    const borderRadius = '10px';

    return (
        <Modal
            centered
            width={width}
            bodyStyle={{ height, padding: '0px', borderRadius }}
            style={{ borderRadius: '10px' }}
            onCancel={handleClose}
            title={null}
            footer={null}
            closable={true}
            destroyOnClose={true}
            maskClosable={false}
            visible={visible}
        >
            <div className={styles.container}>
                {children}
            </div>
        </Modal>
    );
};

Dialog.Header = Header;
Dialog.Body = Body;
Dialog.Footer = Footer;

export default Dialog;
