// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".app_app__OsQoD {\n  background-color: #fbfbfb;\n  position: relative; }\n  .app_app__OsQoD .app_body__nUgCV {\n    flex: 1 0;\n    padding: 16px;\n    align-items: flex-start;\n    background-color: #f7f8fb;\n    position: relative; }\n\n@media (max-width: 480px) {\n  .app_navigation__ldHWz {\n    display: none; } }\n", "",{"version":3,"sources":["webpack://./src/app.module.scss"],"names":[],"mappings":"AAEA;EACI,yBAAyB;EACzB,kBAAkB,EAAA;EAFtB;IAKQ,SAAa;IACb,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB,EAAA;;AAK1B;EACI;IACI,aAAa,EAAA,EAChB","sourcesContent":["@import \"styles/mixins\";\n\n.app {\n    background-color: #fbfbfb;\n    position: relative;\n\n    .body {\n        flex: 1 0 0px;\n        padding: 16px;\n        align-items: flex-start;\n        background-color: #f7f8fb;\n        position: relative;\n    }\n\n}\n\n@media (max-width: 480px) {\n    .navigation {\n        display: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": "app_app__OsQoD",
	"body": "app_body__nUgCV",
	"navigation": "app_navigation__ldHWz"
};
export default ___CSS_LOADER_EXPORT___;
