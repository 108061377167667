import React from 'react';
import { Spin } from 'antd';

// styles
import styles from './loading.module.scss';

export default function Loading({ visible, gradientSpinnerStyle = {}, ...props }) {
    if (!visible) {
        return null;
    }
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff78',
            position: 'absolute',
            top: '0px',
            left: '0px',
            width: '100%',
            height: '100%',
        }} {...props}>
            <Spin
                indicator={
                    <div className={styles.main_cont}>
                        <div className={styles.gradient_spinner} style={gradientSpinnerStyle} />
                    </div>
                }
                style={{ flex: '1 1 0px' }}
            />
        </div>
    );
}
