import React, { useImperativeHandle, forwardRef } from 'react';
import { Modal } from 'antd';

// eslint-disable-next-line react/display-name
const ConfirmDialog = forwardRef(({ title, onOk, onCancel, content }, ref) => {
    const [modal, contextHolder] = Modal.useModal();
    useImperativeHandle(ref, () => ({
        confirmClick(params) {
            modal.confirm({
                title,
                content,
                onOk() {
                    if (onOk) {
                        onOk(params);
                    }
                },
                onCancel() {
                    if (onCancel) {
                        onCancel(params);
                    }
                }
            });
        }
    }));

    return <div>{contextHolder}</div>;
});

export default ConfirmDialog;
