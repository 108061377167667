// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_container__iGugF {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  width: 100%;\n  max-width: 1366px;\n  height: 100%;\n  overflow: hidden; }\n", "",{"version":3,"sources":["webpack://./src/components/container/container.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EAEpB,WAAW;EACX,iBAAiB;EAEjB,YAAY;EACZ,gBAAgB,EAAA","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n\n    width: 100%;\n    max-width: 1366px;\n    // height: fit-content;\n    height: 100%;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_container__iGugF"
};
export default ___CSS_LOADER_EXPORT___;
